import { DERIVED_STATS, DerivedStatInfo } from "data/derivedStats";
import {
  RootState,
  CharacterBaseStats,
  CharacterDerivedStats,
  InstalledUpgradesData,
  CharacterData,
  DerivedStatBuffs,
} from "types";
import {
  calculateCurrentLevelExp,
  calculateNextLevelExp,
  calculateTotalBaseStats,
  calculateCurrentBaseStats,
  getUpgradeData,
  getUpgradesStats,
  getShipData,
  getPilotSkills,
  calculateMobBaseStats,
  getMobData,
  calculateBasicDerivedStat,
} from "utils/stats";

export const getCharacter = (state: RootState) => {
  const { character } = state;

  return {
    userId: character.userId,
    userName: character.userName,
    data: getFormattedCharacterData(character.data),
  };
};

export const getOpponent = (state: RootState) => {
  const { opponent } = state.fight;
  const { slug, weakenedBaseStats, derivedStatBuffs } = opponent;

  const {
    name,
    level,
    baseStatsModifiers,
    baseStatsCosts,
    baseStatsTargets,
    installedUpgrades,
    drops,
  } = getMobData(slug);

  // Calculate Mob base stats on level and modifiers
  const totalBaseStats = calculateMobBaseStats(
    level,
    baseStatsModifiers,
    baseStatsCosts
  );

  // Get max value for bars
  const maxBaseStatValue = Math.max(...Object.values(totalBaseStats));

  // Get current base stats after weakened parts
  const currentBaseStats = calculateCurrentBaseStats(
    totalBaseStats,
    weakenedBaseStats
  );

  const upgradesStats = getUpgradesStats(
    installedUpgrades,
    currentBaseStats,
    totalBaseStats
  );

  const derivedStats = getDerivedStats(
    currentBaseStats,
    upgradesStats,
    derivedStatBuffs
  );

  return {
    ...opponent,
    name,
    currentBaseStats,
    totalBaseStats,
    maxBaseStatValue,
    stats: derivedStats,
    baseStatsTargets,
    drops,
  };
};

export const getOnlinePlayers = (state: RootState) => {
  const { game } = state;

  const onlinePlayersData = game.onlineUsers.map((user) => {
    return {
      userName: user.userName,
      data: getFormattedCharacterData(user.characterData),
    };
  });

  return onlinePlayersData;
};

export const getFormattedCharacterData = (characterData: CharacterData) => {
  const {
    ship,
    pilot,
    level,
    experience,
    enhancedBaseStats,
    trainedSkills,
    weakenedBaseStats,
    derivedStatBuffs,
    installedUpgrades,
  } = characterData;

  // Combine ship's initial stats with earned added base stats
  let totalBaseStats: CharacterBaseStats = enhancedBaseStats;
  if (ship) {
    const shipData = getShipData(ship);
    totalBaseStats = calculateTotalBaseStats(
      shipData.startingBaseStats,
      enhancedBaseStats
    );
  }

  // Get max value for bars
  const maxBaseStatValue = Math.max(...Object.values(totalBaseStats));

  // Get current base stats after weakened parts
  const currentBaseStats = calculateCurrentBaseStats(
    totalBaseStats,
    weakenedBaseStats
  );

  const upgradesStats = getUpgradesStats(
    installedUpgrades,
    currentBaseStats,
    totalBaseStats
  );

  const derivedStats = getDerivedStats(
    currentBaseStats,
    upgradesStats,
    derivedStatBuffs
  );

  return {
    ...characterData,
    currentLevelExp: calculateCurrentLevelExp(experience),
    nextLevelExp: calculateNextLevelExp(level),
    currentBaseStats,
    totalBaseStats,
    maxBaseStatValue,
    stats: derivedStats,
    shipUpgrades: getShipUpgrades(installedUpgrades),
    skills: getPilotSkills(
      pilot,
      trainedSkills,
      derivedStats.fasterRecharge,
      derivedStats.energyMultiplier
    ),
  };
};

export const getFight = (state: RootState) => {
  return state.fight;
};

export const getGameState = (state: RootState) => {
  return state.game;
};

// Calculate derived stats
const calculateTotalDerivedStat = (
  derivedStat: DerivedStatInfo,
  baseStatValue: number,
  upgradeValue: number,
  buffValues: number[] = []
) => {
  const basicDerivedStat = calculateBasicDerivedStat(
    derivedStat,
    baseStatValue
  );
  return derivedStat.rounder(
    buffValues.reduce(
      (acc, buff) => acc * buff,
      basicDerivedStat + upgradeValue
    )
  );
};

export const getDerivedStats = (
  baseStats: CharacterBaseStats,
  upgrades: CharacterDerivedStats,
  buffs: DerivedStatBuffs
): CharacterDerivedStats => {
  const { firepower, resilience, speed, precision, energy } = baseStats;

  return {
    maxHealth: calculateTotalDerivedStat(
      DERIVED_STATS.maxHealth,
      resilience,
      upgrades.maxHealth,
      buffs.maxHealth
    ),
    attackDamage: calculateTotalDerivedStat(
      DERIVED_STATS.attackDamage,
      firepower,
      upgrades.attackDamage,
      buffs.attackDamage
    ),
    weakenParts: calculateTotalDerivedStat(
      DERIVED_STATS.weakenParts,
      firepower,
      upgrades.weakenParts,
      buffs.weakenParts
    ),
    attackSpeed: calculateTotalDerivedStat(
      DERIVED_STATS.attackSpeed,
      speed,
      upgrades.attackSpeed,
      buffs.attackSpeed
    ),
    attackAccuracy: calculateTotalDerivedStat(
      DERIVED_STATS.attackAccuracy,
      precision,
      upgrades.attackAccuracy,
      buffs.attackAccuracy
    ),
    damageReduction: calculateTotalDerivedStat(
      DERIVED_STATS.damageReduction,
      resilience,
      upgrades.damageReduction,
      buffs.damageReduction
    ),
    weakenPartsReduction: calculateTotalDerivedStat(
      DERIVED_STATS.weakenPartsReduction,
      resilience,
      upgrades.weakenPartsReduction,
      buffs.weakenPartsReduction
    ),
    criticalHitChance: calculateTotalDerivedStat(
      DERIVED_STATS.criticalHitChance,
      precision,
      upgrades.criticalHitChance,
      buffs.criticalHitChance
    ),
    criticalHitMultiplier: calculateTotalDerivedStat(
      DERIVED_STATS.criticalHitMultiplier,
      firepower,
      upgrades.criticalHitMultiplier,
      buffs.criticalHitMultiplier
    ),
    dodgeChance: calculateTotalDerivedStat(
      DERIVED_STATS.dodgeChance,
      speed,
      upgrades.dodgeChance,
      buffs.dodgeChance
    ),
    movementSpeed: calculateTotalDerivedStat(
      DERIVED_STATS.movementSpeed,
      speed,
      upgrades.movementSpeed,
      buffs.movementSpeed
    ),
    fasterRecharge: calculateTotalDerivedStat(
      DERIVED_STATS.fasterRecharge,
      energy,
      upgrades.fasterRecharge,
      buffs.fasterRecharge
    ),
    energyMultiplier: calculateTotalDerivedStat(
      DERIVED_STATS.energyMultiplier,
      energy,
      upgrades.energyMultiplier,
      buffs.energyMultiplier
    ),
  };
};

export const getShipUpgrades = (installedUpgrades?: InstalledUpgradesData) => {
  installedUpgrades = installedUpgrades || {
    weapons: null,
    shields: null,
    thrusters: null,
    targetingSystem: null,
    reactorCore: null,
  };

  return {
    weapons: getUpgradeData(installedUpgrades.weapons || "default_weapons"),
    shields: getUpgradeData(installedUpgrades.shields || "default_shields"),
    thrusters: getUpgradeData(
      installedUpgrades.thrusters || "default_thrusters"
    ),
    targetingSystem: getUpgradeData(
      installedUpgrades.targetingSystem || "default_targeting_system"
    ),
    reactorCore: getUpgradeData(
      installedUpgrades.reactorCore || "default_reactor_core"
    ),
  };
};
