import React from "react";
import styled, { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import firepowerImage from "assets/stat-firepower.svg";
import resilienceImage from "assets/stat-resilience.svg";
import speedImage from "assets/stat-speed.svg";
import precisionImage from "assets/stat-precision.svg";
import energyImage from "assets/stat-energy.svg";
import { COLORS } from "utils/constants";
import {
  targetWeapons,
  targetShields,
  targetThrusters,
  targetTargetingComputer,
  targetReactor,
  activateSkill,
  employFightItem,
} from "redux/actions";
import { getCharacter, getFight, getOpponent } from "redux/selectors";
import {
  getFightItems,
  getMinAttackDamage,
  getMinWeakenParts,
  getTotalWeakenedIntegrity,
} from "utils/stats";
import Skill from "components/base/Skill";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import ObjectIcon from "components/base/ObjectIcon";

interface FightActionsProps {
  $isEnabled: boolean;
}

interface ItemProps {
  $isDisabled: boolean;
}

interface PartIconProps {
  image: string;
  color: string;
}

const disabledMixin = css`
  opacity: 0.3;
  pointer-events: none;
  filter: saturate(0);
`;

const FightActionsWrapper = styled.div<FightActionsProps>`
  color: white;
  opacity: 0.4;
  pointer-events: none;
  ${(props) => props.$isEnabled && `opacity: 1;`}
  ${(props) => props.$isEnabled && `pointer-events: auto;`}
  transition: all 200ms ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const FightActionGroups = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Parts = styled.div`
  display: flex;
  gap: 10px;
`;

const Part = styled.div`
  background-color: #0c434c70;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0c434c;
  }
`;

const PartIcon = styled.div<PartIconProps>`
  height: 24px;
  width: 24px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const Skills = styled.div`
  display: flex;
  gap: 10px;
`;

const Items = styled.div`
  display: flex;
  gap: 10px;
`;

const ItemQuantity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000090;
  color: white;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  transition: opacity 200ms ease-out;
`;

const Item = styled.div<ItemProps>`
  width: 44px;
  height: 44px;
  cursor: pointer;
  position: relative;

  &:hover ${ItemQuantity} {
    opacity: 1;
  }

  ${(props) => props.$isDisabled && disabledMixin}
`;

const TARGET_PARTS = [
  {
    name: "weapons",
    stat: "firepower",
    image: firepowerImage,
    color: COLORS.FIREPOWER,
    action: targetWeapons,
  },
  {
    name: "shields",
    stat: "resilience",
    image: resilienceImage,
    color: COLORS.RESILIENCE,
    action: targetShields,
  },
  {
    name: "thrusters",
    stat: "speed",
    image: speedImage,
    color: COLORS.SPEED,
    action: targetThrusters,
  },
  {
    name: "targeting grid",
    stat: "precision",
    image: precisionImage,
    color: COLORS.PRECISION,
    action: targetTargetingComputer,
  },
  {
    name: "reactor core",
    stat: "antimatter",
    image: energyImage,
    color: COLORS.ENERGY,
    action: targetReactor,
  },
];

export default function FightActions() {
  const { data: characterData } = useSelector(getCharacter);
  const {
    health,
    skillsRecharge,
    inventory,
    skills,
    stats,
    weakenedBaseStats,
  } = characterData;
  const { areActionsEnabled } = useSelector(getFight);
  const opponent = useSelector(getOpponent);
  const dispatch = useDispatch();

  const activeSkills = skills.filter(
    (skill) => !!skill.isActive && skill.isTrained
  );
  const fightItems = getFightItems(inventory.items);
  const isFullHealth = health >= stats.maxHealth;
  const integrityToRestore = getTotalWeakenedIntegrity(weakenedBaseStats);
  const isFullIntegrity = integrityToRestore <= 0;

  const minAttackDamage = getMinAttackDamage(
    stats.attackDamage,
    stats.attackAccuracy
  );
  const minWeakenParts = getMinWeakenParts(
    stats.weakenParts,
    stats.attackAccuracy
  );

  return (
    <FightActionsWrapper $isEnabled={areActionsEnabled}>
      <FightActionGroups>
        <Parts>
          {TARGET_PARTS.map((part) => (
            <Trigger key={part.name}>
              <Part onClick={() => dispatch(part.action())}>
                <PartIcon image={part.image} color={part.color} />
              </Part>
              <Tooltip>
                <TooltipName>Attack: {part.name}</TooltipName>
                <TooltipDesc>
                  Inflict{" "}
                  <strong>{`${minAttackDamage} - ${stats.attackDamage}`}</strong>
                  {` damage and weaken ${part.stat} by `}
                  <strong>{`${minWeakenParts} - ${stats.weakenParts}`}</strong>
                  {` by targeting ${opponent.name}'s ${part.name}. `}
                </TooltipDesc>
              </Tooltip>
            </Trigger>
          ))}
        </Parts>
        <Skills>
          {activeSkills.map((skill) => {
            const rechargeTurns = skillsRecharge[skill.slug] || 0;
            const totalRecharge = skill.recharge || rechargeTurns;
            const rechargePercentage = (rechargeTurns / totalRecharge) * 100;
            const isRecharging = rechargeTurns > 0;
            return (
              <Skill
                key={skill.slug}
                skill={skill}
                characterData={characterData}
                isRecharging={isRecharging}
                rechargeTurns={rechargeTurns}
                rechargePercentage={rechargePercentage}
                onClick={() =>
                  !isRecharging && dispatch(activateSkill(skill.slug))
                }
              />
            );
          })}
        </Skills>
        <Items>
          {fightItems.map((item) => {
            let isDisabled = false;
            // Disable health repair kits if at full health
            if (item.attribute === "health" && isFullHealth) {
              isDisabled = true;
            }
            // Disable parts restore modules if at full parts integrity
            if (item.attribute === "integrity" && isFullIntegrity) {
              isDisabled = true;
            }
            return (
              <Item
                key={item.slug}
                $isDisabled={isDisabled}
                onClick={() => dispatch(employFightItem(item.slug))}
              >
                <ObjectIcon slug={item.slug} />
                <ItemQuantity>x{item.quantity}</ItemQuantity>
              </Item>
            );
          })}
        </Items>
      </FightActionGroups>
    </FightActionsWrapper>
  );
}
