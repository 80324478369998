import { PilotModel, PilotTypes } from "types";

import weaponsSpecialistAvatarImage from "assets/pilot-weapons-specialist.svg";
import stuntPilotAvatarImage from "assets/pilot-stunt-pilot.svg";
import mechanicalEngineerAvatarImage from "assets/pilot-mechanical-engineer.svg";
import physicistAvatarImage from "assets/pilot-physicist.svg";
import sharpshooterAvatarImage from "assets/pilot-sharpshooter.svg";
import shieldBreakerSkillImage from "assets/skill-shieldbreaker.svg";
import tripleShotSkillImage from "assets/skill-tripleshot.svg";
import emergencyRepairsSkillImage from "assets/skill-emergencyrepairs.svg";
import energyBlastSkillImage from "assets/skill-energyblast.svg";
import cantMissSkillImage from "assets/skill-cantmiss.svg";

import {
  energyBlastSaga,
  repairSaga,
  rapidFireSaga,
  pressurePointSaga,
  shieldBreakerSaga,
} from "redux/sagas/skill";
import {
  getEmergencyRepairHealth,
  getEnergyBlastDamage,
  getPressurePointDamage,
  getRapidFireDamage,
  getShieldBreakerDamage,
} from "utils/stats";

export const PILOTS: Record<PilotTypes, PilotModel> = {
  weaponsSpecialist: {
    name: "Weapons Specialist",
    description:
      "Weapons Specialists love working on their ship's weapons (maybe too much, ew). They bring skills in upping their attack power.",
    images: {
      avatar: weaponsSpecialistAvatarImage,
    },
    skills: [
      {
        name: "Shield Breaker",
        slug: "shieldBreaker",
        image: shieldBreakerSkillImage,
        description: (formattedCharacterData) => {
          const { attackWeakenParts } = getShieldBreakerDamage(
            formattedCharacterData
          );
          return `Set yourself up to inflict greater damage later by weakening enemy shields by <strong>${attackWeakenParts}</strong>.`;
        },
        medallions: 0,
        isDefault: true,
        isActive: true,
        saga: shieldBreakerSaga,
        payload: {},
        recharge: 10,
        hasAntimatterBoost: true,
      },
    ],
  },
  stuntPilot: {
    name: "Stunt Pilot",
    description:
      "Stunt Pilots are skilled in agile, nimble flying. This allows them to attack more often and deftly dodge attacks. Miss me with that.",
    images: {
      avatar: stuntPilotAvatarImage,
    },
    skills: [
      {
        name: "Rapid Fire",
        slug: "rapidFire",
        image: tripleShotSkillImage,
        description: (formattedCharacterData) => {
          const { attackDamage, attackWeakenParts } = getRapidFireDamage(
            formattedCharacterData
          );
          return `Fire three average damage lasers, inflicting <strong>${attackDamage}</strong> damage and weakening speed by <strong>${attackWeakenParts}</strong> with each shot.`;
        },
        medallions: 0,
        isDefault: true,
        isActive: true,
        saga: rapidFireSaga,
        payload: {},
        recharge: 8,
        hasAntimatterBoost: true,
      },
    ],
  },
  mechanicalEngineer: {
    name: "Mechanical Engineer",
    description:
      "Mechanical Engineers are skilled in starship repair and enhancing their shields. Defense wins championships.",
    images: {
      avatar: mechanicalEngineerAvatarImage,
    },
    skills: [
      {
        name: "Emergency Repairs",
        slug: "emergencyRepairs",
        image: emergencyRepairsSkillImage,
        description: (formattedCharacterData) => {
          const { healPercentage } = getEmergencyRepairHealth(
            formattedCharacterData
          );
          return `Quickly repair <strong>${Math.round(
            healPercentage * 100
          )}%</strong> of your ship health.`;
        },
        medallions: 0,
        isDefault: true,
        isActive: true,
        saga: repairSaga,
        payload: {},
        recharge: 10,
        hasAntimatterBoost: true,
      },
    ],
  },
  physicist: {
    name: "Physicist",
    description:
      "Physicists are nerds who mainly focus on antimatter energy. This gives their ship all sorts of interesting abilities.",
    images: {
      avatar: physicistAvatarImage,
    },
    skills: [
      {
        name: "Energy Blast",
        slug: "energyBlast",
        image: energyBlastSkillImage,
        description: (formattedCharacterData) => {
          const { attackDamage } = getEnergyBlastDamage(formattedCharacterData);
          return `Fire a potentially giant blast of antimatter energy depending on your antimatter stat, dealing <strong>${attackDamage}</strong> damage.`;
        },
        medallions: 0,
        isDefault: true,
        isActive: true,
        saga: energyBlastSaga,
        payload: {},
        recharge: 12,
        hasAntimatterBoost: true,
      },
    ],
  },
  sharpshooter: {
    name: "Sharpshooter",
    description:
      "Sharpshooters will hit you where it hurts the most. Some call it luck, they call it skill. They're kind of obnoxious.",
    images: {
      avatar: sharpshooterAvatarImage,
    },
    skills: [
      {
        name: "Pressure Point",
        slug: "pressurePoint",
        image: cantMissSkillImage,
        description: (formattedCharacterData) => {
          const { attackDamage } = getPressurePointDamage(
            formattedCharacterData
          );
          return `Start with minimum damage, which doubles every shot, up to 4 times. This next shot will inflict <strong>${attackDamage}</strong> damage.`;
        },
        medallions: 0,
        isDefault: true,
        isActive: true,
        saga: pressurePointSaga,
        payload: {},
        recharge: 10,
        hasAntimatterBoost: true,
      },
      // {
      //   name: "Can't Miss",
      //   slug: "cantMiss",
      //   image: cantMissSkillImage,
      //   description: () => "Fire a shot that will hit every. single. time.",
      //   medallions: 0,
      //   isDefault: true,
      //   isActive: true,
      //   saga: rapidFireSaga, // TODO: Replace placeholder
      //   payload: {},
      //   recharge: 5,
      //   hasAntimatterBoost: true,
      // },
    ],
  },
};
