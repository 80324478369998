import { DERIVED_STATS } from "data/derivedStats";
import { PARTS } from "data/parts";
import styled from "styled-components";
import { CharacterDerivedStats } from "types";

import creditsImage from "assets/credits.svg";
import { getItemData, getUpgradeData, isItemType } from "utils/stats";
import Tooltip, {
  CreditsIcon,
  StatIcon,
  TooltipCredits,
  TooltipDesc,
  TooltipGrade,
  TooltipInfo,
  TooltipName,
  TooltipRequirement,
  TooltipStat,
  TooltipType,
  Trigger,
} from "components/base/Tooltip";
import { ATTRIBUTES } from "data/attributes";
import { BASE_STATS_INFO } from "data/baseStats";

interface ObjectProps {
  slug: string;
}

interface UpgradeImageWrapperProps {
  color: string;
}

const UpgradeImageWrapper = styled.div<UpgradeImageWrapperProps>`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  background: linear-gradient(#000000, 60%, ${(props) => props.color}85);
  background-blend-mode: darken;
`;

const ObjectImage = styled.img`
  width: 100%;
  display: block;
  border-radius: 4px;
  overflow: hidden;
`;

const ObjectIcon = ({ slug }: ObjectProps) => {
  const isItem = !!isItemType(slug);

  const item = isItem ? getItemData(slug) : null;
  const upgrade = !isItem ? getUpgradeData(slug) : null;

  if (!!item) {
    const attributeInfo = ATTRIBUTES[item.attribute];
    return (
      <Trigger>
        <ObjectImage src={item.icon} />
        <Tooltip>
          <TooltipName>{item.name}</TooltipName>
          <TooltipType>Item</TooltipType>
          <TooltipDesc>{item.description}</TooltipDesc>
          <TooltipInfo>
            <TooltipStat>
              <StatIcon image={attributeInfo.image} />
              {attributeInfo.name}: {attributeInfo.formatter(item.payload)}
            </TooltipStat>
            <TooltipCredits>
              <CreditsIcon image={creditsImage} />
              Credits: {item.credits}
            </TooltipCredits>
          </TooltipInfo>
        </Tooltip>
      </Trigger>
    );
  }

  if (!!upgrade) {
    const { gradeData, requirement } = upgrade;
    const partInfo = PARTS[upgrade.part];
    const baseStatReq = BASE_STATS_INFO.find(
      (stat) => stat.slug === requirement.baseStat
    );

    return (
      <Trigger>
        <UpgradeImageWrapper color={upgrade.gradeData.color}>
          <ObjectImage src={upgrade.icon} />
        </UpgradeImageWrapper>
        <Tooltip>
          <TooltipName color={gradeData.color}>{upgrade.name}</TooltipName>
          {!upgrade.isDefault && (
            <TooltipGrade color={gradeData.color}>
              {`${gradeData.name} ${partInfo.name}`}
            </TooltipGrade>
          )}
          {!!baseStatReq && requirement.value > 0 && (
            <TooltipRequirement color={baseStatReq.color}>
              {`${baseStatReq.name} required: ${requirement.value}`}
            </TooltipRequirement>
          )}
          <TooltipDesc>{upgrade.description}</TooltipDesc>
          <TooltipInfo>
            {Object.entries(upgrade.derivedStats).map(([key, value]) => {
              const statSlug = key as keyof CharacterDerivedStats;
              const statInfo = DERIVED_STATS[statSlug];
              return (
                <TooltipStat key={statSlug}>
                  <StatIcon image={statInfo.image} />
                  {statInfo.name}: {statInfo.addFormatter(value)}
                </TooltipStat>
              );
            })}
            {!upgrade.isDefault && (
              <TooltipCredits>
                <CreditsIcon image={creditsImage} />
                {`Credits: ${upgrade.credits}`}
              </TooltipCredits>
            )}
          </TooltipInfo>
        </Tooltip>
      </Trigger>
    );
  }

  return <></>;
};

export default ObjectIcon;
