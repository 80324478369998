import styled from "styled-components";
import parse from "html-react-parser";
import { CharacterDerivedStats, SkillModel } from "types";

import Tooltip, {
  TooltipName,
  TooltipDesc,
  TooltipInfo,
  TooltipBuff,
  Trigger,
  TooltipRecharge,
  RechargeIcon,
  BuffIcon,
} from "./Tooltip";
import { DERIVED_STATS } from "data/derivedStats";
import energyImage from "assets/stat-energy.svg";
import { COLORS } from "utils/constants";

interface SkillProps {
  skill: SkillModel;
  characterData: any;
  isRecharging?: boolean;
  rechargeTurns?: number;
  rechargePercentage?: number;
  onClick?: () => void;
}

interface SkillRechargeProps {
  percentage: number;
}

interface SkillImageProps {
  isRecharging: boolean;
}

const SkillWrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
  height: 44px;
  width: 44px;
  cursor: pointer;
  position: relative;

  &:hover {
    filter: saturate(150%);
  }
`;

const SkillRechargeValue = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 2;
`;

const SkillRecharge = styled.div<SkillRechargeProps>`
  height: ${(props) => props.percentage}%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: black;
  opacity: 0.8;
  transition: all 200ms ease-out;
  z-index: 1;
`;

const SkillImage = styled.img<SkillImageProps>`
  width: 100%;
  height: 100%;
  ${(props) => props.isRecharging && `filter: saturate(0%) brightness(50%);`}
`;

const SkillDesc = styled.div`
  strong {
    color: ${COLORS.ENERGY};
  }
`;

const Skill = ({
  skill,
  characterData,
  isRecharging = false,
  rechargeTurns = 0,
  rechargePercentage = 0,
  onClick,
}: SkillProps) => {
  const boostInfo = DERIVED_STATS.energyMultiplier;

  return (
    <Trigger>
      <SkillWrapper onClick={onClick}>
        {!!isRecharging && (
          <SkillRechargeValue>{rechargeTurns}</SkillRechargeValue>
        )}
        <SkillRecharge percentage={rechargePercentage} />
        <SkillImage src={skill.image} isRecharging={isRecharging} />
      </SkillWrapper>
      <Tooltip>
        <TooltipName>Skill: {skill.name}</TooltipName>
        <TooltipDesc>
          <SkillDesc>{parse(skill.description(characterData))}</SkillDesc>
        </TooltipDesc>
        <TooltipInfo>
          {Object.entries(skill.payload)
            .filter(([key, value]) => {
              // Only show buff info for derived stat modifiers
              return key in DERIVED_STATS;
            })
            .map(([key, value]) => {
              const statSlug = key as keyof CharacterDerivedStats;
              const statMultiplier = value as number;
              const statInfo = DERIVED_STATS[statSlug];

              const isBuff = statMultiplier >= 1;
              return (
                <TooltipBuff key={statSlug} isBuff={isBuff}>
                  <BuffIcon image={statInfo.image} isBuff={isBuff} />
                  {`${statInfo.name} Multiplier:
                ${statInfo.buffFormatter(statMultiplier)}`}
                </TooltipBuff>
              );
            })}
          {!!skill.hasAntimatterBoost && (
            <TooltipRecharge>
              <RechargeIcon image={energyImage} />
              {`Antimatter Boost: ${boostInfo.formatter(
                characterData.stats.energyMultiplier
              )}`}
            </TooltipRecharge>
          )}
          <TooltipRecharge>
            <RechargeIcon image={energyImage} />
            {`Turns to Recharge: ${skill.recharge}`}
          </TooltipRecharge>
        </TooltipInfo>
      </Tooltip>
    </Trigger>
  );
};

export default Skill;
