import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { getCharacter, getFight, getOpponent } from "redux/selectors";
import FightActions from "components/ui/FightActions";
import FightPreview from "components/ui/FightPreview";
import FightResults from "components/ui/FightResults";
import FightStats from "components/base/FightStats";
import FightBuffs from "./FightBuffs";
import Tooltip, { TooltipName, Trigger } from "components/base/Tooltip";

interface FightWrapperProps {
  isShowing: boolean;
}

interface CurrentTurnProps {
  isCharacterTurn: boolean;
}

interface NextTurnProps {
  isCharacterNextTurn: boolean;
}

const FightWrapper = styled.div<FightWrapperProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  ${(props) => props.isShowing && `opacity: 1;`}
  ${(props) => props.isShowing && `pointer-events: auto;`}
  transition: all 500ms ease-out;
`;

const FightScreen = styled.div`
  position: absolute;
  top: 5vh;
  bottom: 5vh;
  left: 5vh;
  right: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

const CharacterBuffs = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const MobBuffs = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
`;

const CurrentTurnWrapper = styled.div<CurrentTurnProps>`
  position: absolute;
  z-index: 0;
  height: 100%;
  transition: all 400ms ease-out;
  ${(props) =>
    props.isCharacterTurn
      ? `left: calc(0% - 5px);`
      : `left: calc(100% - 5px - 4px);`}
`;

const CurrentTurn = styled.div`
  width: 4px;
  height: 100%;
  margin: 0 5px;
  background-color: #43fffa;
  border-radius: 2px;
`;

const NextTurnWrapper = styled.div<NextTurnProps>`
  position: absolute;
  z-index: 0;
  height: 75%;
  transition: all 400ms ease-out;
  ${(props) =>
    props.isCharacterNextTurn
      ? `left: calc(0% - 12px - 5px);`
      : `left: calc(100% + 12px - 5px - 2px);`}
`;

const NextTurn = styled.div`
  width: 2px;
  height: 100%;
  margin: 0 5px;
  background-color: #43fffa50;
  border-radius: 1px;
`;

const FightStatsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
`;

export default function Fight() {
  const {
    userName,
    data: { derivedStatBuffs },
  } = useSelector(getCharacter);
  const { status, isCharacterTurn, isCharacterNextTurn } =
    useSelector(getFight);
  const opponent = useSelector(getOpponent);

  const isFightMode = [
    "preview",
    "fighting",
    "winResults",
    "loseResults",
  ].includes(status);
  const isResultsMode = ["winResults", "loseResults"].includes(status);

  return (
    <FightWrapper isShowing={isFightMode}>
      {status === "preview" && <FightPreview />}
      {status === "fighting" && (
        <>
          <FightScreen>
            <FightActions />
            <CharacterBuffs>
              <FightBuffs name={userName} buffs={derivedStatBuffs} />
            </CharacterBuffs>
            <FightStatsWrapper>
              <NextTurnWrapper isCharacterNextTurn={isCharacterNextTurn}>
                <Trigger>
                  <NextTurn />
                  <Tooltip isAbove isLeft={isCharacterNextTurn}>
                    <TooltipName>
                      {`Next Turn: ${
                        isCharacterNextTurn ? userName : opponent.name
                      }`}
                    </TooltipName>
                  </Tooltip>
                </Trigger>
              </NextTurnWrapper>
              <CurrentTurnWrapper isCharacterTurn={isCharacterTurn}>
                <Trigger>
                  <CurrentTurn />
                  <Tooltip isAbove isLeft={isCharacterTurn}>
                    <TooltipName>
                      {`Current Turn: ${
                        isCharacterTurn ? userName : opponent.name
                      }`}
                    </TooltipName>
                  </Tooltip>
                </Trigger>
              </CurrentTurnWrapper>
              <FightStats />
            </FightStatsWrapper>
            <MobBuffs>
              <FightBuffs
                name={opponent.name}
                buffs={opponent.derivedStatBuffs}
              />
            </MobBuffs>
          </FightScreen>
        </>
      )}
      {isResultsMode && <FightResults />}
    </FightWrapper>
  );
}
