import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { getCharacter, getOpponent } from "redux/selectors";
import WeakenBar from "components/base/WeakenBar";
import { CharacterBaseStats } from "types";
import { BASE_STATS_INFO } from "data/baseStats";
import Tooltip, { TooltipDesc, TooltipName, Trigger } from "./Tooltip";

interface StatIconProps {
  image: string;
  color: string;
}

const FightStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;
`;

const NamesWrapper = styled.div`
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  color: white;
  text-transform: uppercase;
`;

const StatWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StatIcon = styled.div<StatIconProps>`
  height: 16px;
  width: 16px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

export default function Fight() {
  const {
    userName,
    data: { currentBaseStats, weakenedBaseStats, maxBaseStatValue },
  } = useSelector(getCharacter);
  const opponent = useSelector(getOpponent);

  // Pick the max value of both character and opponent as baseline
  const maxValue = Math.max(maxBaseStatValue, opponent.maxBaseStatValue);

  return (
    <FightStatsWrapper>
      <NamesWrapper>
        <div>{userName}</div>
        <div>{opponent.name}</div>
      </NamesWrapper>
      {BASE_STATS_INFO.map((stat) => {
        const statSlug = stat.slug as keyof CharacterBaseStats;
        return (
          <StatWrapper key={stat.slug}>
            <WeakenBar
              number={currentBaseStats[statSlug]}
              weakenNumber={weakenedBaseStats[statSlug]}
              maxNumber={maxValue}
              color={stat.color}
              isReversed={true}
            />
            <Trigger>
              <StatIcon image={stat.image} color={stat.color} />
              <Tooltip isAbove>
                <TooltipName>Attribute: {stat.name}</TooltipName>
                <TooltipDesc>{stat.description}</TooltipDesc>
                <TooltipDesc>{`A ship's ${stat.name} can be weakened in battle by targeting its ${stat.part}.`}</TooltipDesc>
              </Tooltip>
            </Trigger>
            <WeakenBar
              number={opponent.currentBaseStats[statSlug]}
              weakenNumber={opponent.weakenedBaseStats[statSlug]}
              maxNumber={maxValue}
              color={stat.color}
              isReversed={false}
            />
          </StatWrapper>
        );
      })}
    </FightStatsWrapper>
  );
}
