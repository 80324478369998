import { BASE_STATS } from "data/baseStats";
import { call, delay, put, select, spawn } from "redux-saga/effects";

import {
  showMessage,
  setSkillRecharge,
  animateAction,
  endAnimation,
  setSkillValue,
} from "redux/actions";
import { getCharacter } from "redux/selectors";
import { SkillModel } from "types";
import { characterAttackAnimation, characterSkillAttackSaga } from "./fight";
import { healSaga } from "./character";
import {
  getEmergencyRepairHealth,
  getEnergyBlastDamage,
  getPressurePointDamage,
  getRapidFireDamage,
  getShieldBreakerDamage,
} from "utils/stats";

export function* repairSaga(skillSlug: string) {
  const { data } = yield select(getCharacter);

  const { healAmount } = getEmergencyRepairHealth(data);

  yield call(healSaga, healAmount);

  yield spawn(characterSkillAnimation, skillSlug);
}

export function* rapidFireSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let rapid fire animation play before shots are fired
  yield delay(1000);

  const { data } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getRapidFireDamage(data);

  const NUMBER_OF_ATTACKS = 3;
  for (var i = 0; i < NUMBER_OF_ATTACKS; i++) {
    if (i > 0) {
      yield delay(500);
    }
    yield spawn(characterAttackAnimation);
    // Let skill attack hit first before showing opponent damage
    yield delay(100);
    yield call(
      characterSkillAttackSaga,
      attackDamage,
      attackWeakenParts,
      BASE_STATS.SPEED
    );
  }
}

export function* energyBlastSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill attack hit first before showing opponent damage
  yield delay(600);

  const { data } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getEnergyBlastDamage(data);

  yield call(characterSkillAttackSaga, attackDamage, attackWeakenParts);
}

export function* pressurePointSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill attack hit first before showing opponent damage
  yield delay(100);

  const { data } = yield select(getCharacter);
  const { skillsValues } = data;

  const { attackDamage, attackWeakenParts } = getPressurePointDamage(data);

  yield call(characterSkillAttackSaga, attackDamage, attackWeakenParts);

  // Keep track of how many time this skill is used
  const useCount = skillsValues[skillSlug] || 0;
  const newUseCount = useCount + 1;
  yield put(setSkillValue({ slug: skillSlug, value: newUseCount }));
}

export function* shieldBreakerSaga(skillSlug: string) {
  yield spawn(characterSkillAnimation, skillSlug);
  // Let skill attack hit first before showing opponent damage
  yield delay(500);

  const { data } = yield select(getCharacter);

  const { attackDamage, attackWeakenParts } = getShieldBreakerDamage(data);

  yield call(
    characterSkillAttackSaga,
    attackDamage,
    attackWeakenParts,
    BASE_STATS.RESILIENCE
  );
}

export function* activateSkillSaga(skillSlug: string) {
  const {
    data: { skillsRecharge, skills },
  } = yield select(getCharacter);

  try {
    const skill = skills.find((skill: SkillModel) => skill.slug === skillSlug);
    if (!skill) {
      throw new Error("Skill not found");
    }

    // Check if skill is still recharging
    const rechargeTurns = skillsRecharge[skillSlug];
    if (rechargeTurns !== "undefined" && rechargeTurns > 0) {
      yield put(showMessage("Skill is still recharging"));
      return;
    }

    // Use skill by calling its saga and value
    // Call saga directly instead of yield putting action to keep it synchronous
    yield call(skill.saga, skillSlug, skill.payload);

    // If activated successfully, start recharging skill + 1 to accommodate current turn
    const newRechargeTurns = (skill.recharge || 0) + 1;
    yield put(setSkillRecharge({ slug: skillSlug, turns: newRechargeTurns }));
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

function* characterSkillAnimation(animation: string) {
  // Animate character attack
  yield put(animateAction(animation));

  yield delay(1000);

  // Reset animation
  yield put(endAnimation(animation));
}

export default function* skillSagas() {}
