import {
  call,
  put,
  select,
  delay,
  take,
  takeLeading,
  race,
  spawn,
} from "redux-saga/effects";

import { CharacterBaseStats, DamageModel } from "types";

import { BASE_STATS } from "data/baseStats";
import {
  healSaga,
  gainExpSaga,
  loseExpSaga,
  gainCreditsSaga,
  enterAreaSaga,
} from "redux/sagas/character";
import { employItemSaga, getObjectsSaga } from "redux/sagas/item";
import {
  previewFight,
  startFight,
  continueFight,
  disableMovement,
  enableMovement,
  setupOpponent,
  startCharacterTurn,
  startOpponentTurn,
  attackOpponent,
  targetWeapons,
  targetShields,
  targetThrusters,
  targetTargetingComputer,
  targetReactor,
  resetOpponentDamage,
  resetOpponentParts,
  takeDamage,
  resetDamage,
  animateAction,
  animateOpponentAction,
  showMessage,
  setFightResults,
  resetFightResults,
  resetSkillsRecharge,
  resetBuffs,
  activateSkill,
  rechargeSkillsTurn,
  setMobBattleRating,
  setFightStatus,
  startMobDialog,
  retreatFight,
  restoreAllParts,
  turnShip,
  employFightItem,
  resetOpponentBuffs,
  addCharacterTurnPriority,
  addOpponentTurnPriority,
  resetTurnPriorities,
  onwardFightWin,
  onwardFightLose,
  endFightWin,
  endFightLose,
  fightAgain,
  moveToPosition,
  endOpponentAnimation,
  endAnimation,
  setCharacterNextTurn,
  setOpponentNextTurn,
  enableFightActions,
  disableFightActions,
  resetSkillsValues,
} from "redux/actions";
import {
  calculateMobBaseStats,
  doesEventHappen,
  getOpponentBaseStatTarget,
  getExpGained,
  getCreditsGained,
  getExpLost,
  getMobDrops,
  getMobData,
  calculateBattleRating,
  getBattleRatingData,
  getBuildingData,
  getUpgradesStats,
  calculateMobStartingHealth,
  getDodgeChance,
  getRegularAttackValues,
  getTotalDamageValuesAfterDefense,
  getMultiplierAttackValues,
} from "utils/stats";
import { getCharacter, getFight, getOpponent } from "redux/selectors";
import { activateSkillSaga } from "./skill";
import { DERIVED_STATS } from "data/derivedStats";

export const FIGHT_DISTANCE = 30;

function* startMobDialogSaga({ payload }: { payload: string }) {
  // Set up opponent for fight so we can see their stats
  yield call(setupOpponentSaga, payload);

  // Show opening dialog from Mob
  yield put(setFightStatus("openingDialog"));
}

function* previewFightSaga() {
  // Turn ship to the right to face opponent
  yield put(turnShip("right"));

  // Restrict movement of character
  yield put(disableMovement());

  // Reset character buffs just in case
  yield put(resetBuffs());

  // Reset skills recharging just in case
  yield put(resetSkillsRecharge());

  // Reset skills values just in case
  yield put(resetSkillsValues());

  // Show fight preview window
  yield put(setFightStatus("preview"));
}

function* retreatFightSaga() {
  // Enable character movement
  yield put(enableMovement());

  // Show fight preview window
  yield put(setFightStatus("notFighting"));
}

// startFightSaga
function* startFightSaga() {
  // Set fighting status
  yield put(setFightStatus("fighting"));

  // Show message (make this bigger later)
  yield put(showMessage(`Battle Begins`));

  // Set initial attack priority for character and opponent
  const {
    data: {
      stats: { attackSpeed: characterAttackSpeed },
    },
  } = yield select(getCharacter);
  const {
    slug,
    stats: { attackSpeed: opponentAttackSpeed },
  } = yield select(getOpponent);
  yield put(addCharacterTurnPriority(characterAttackSpeed));
  yield put(addOpponentTurnPriority(opponentAttackSpeed));

  // Position ship in front of opponent
  const { position: mobPosition } = getMobData(slug);
  yield put(moveToPosition(mobPosition - FIGHT_DISTANCE));

  // Get into fight saga
  yield call(fightSaga);
}

// continueFight (calls just fightSaga?)
function* continueFightSaga() {
  const { status } = yield select(getFight);

  if (status === "fighting") {
    // Get into fight saga
    yield call(fightSaga);
  }
}

function* fightAgainSaga() {
  const { opponent } = yield select(getFight);

  // Reset fight data
  yield call(resetFightSaga);

  // Set up opponent health again
  yield call(setupOpponentSaga, opponent.slug);

  // Start fight
  yield call(startFightSaga);
}

function* fightSaga() {
  // Set fight actions as disabled by default
  yield put(disableFightActions());

  // Fight
  while (true) {
    // Get new attack priority each turn, because it may change with weakened parts
    const {
      data: {
        stats: { attackSpeed: characterAttackSpeed },
      },
    } = yield select(getCharacter);
    const {
      stats: { attackSpeed: opponentAttackSpeed },
    } = yield select(getOpponent);
    const { characterTurnPriority, opponentTurnPriority } = yield select(
      getFight
    );

    // check whose priority is higher, it's their turn to attack
    if (characterTurnPriority >= opponentTurnPriority) {
      // CHARACTER'S TURN
      yield put(startCharacterTurn());
      yield put(enableFightActions());

      // Set who's up next turn
      if (characterTurnPriority >= opponentTurnPriority + opponentAttackSpeed) {
        // Character is up next
        yield put(setCharacterNextTurn());
      } else {
        // Opponent is up next
        yield put(setOpponentNextTurn());
      }

      // Character attack sequence
      yield call(characterOptionsSaga);

      // Check if Opponent has died
      const { health: opponentHealth } = yield select(getOpponent);
      if (opponentHealth === 0) {
        yield call(characterWinSaga);
        return false;
      }

      // Increase priority for opponent based on their speed
      yield put(addOpponentTurnPriority(opponentAttackSpeed));
    } else {
      // OPPONENT'S TURN
      yield put(startOpponentTurn());

      // Set who's up next turn
      if (
        opponentTurnPriority >=
        characterTurnPriority + characterAttackSpeed
      ) {
        // Opponent is up next
        yield put(setOpponentNextTurn());
      } else {
        // Character is up next
        yield put(setCharacterNextTurn());
      }

      // Give it a sec to sink in that it's the mob's turn
      yield delay(1000);

      // Opponent attack sequence
      yield call(opponentRegularAttackSaga);

      // Check if Characer has died
      const {
        data: { health },
      } = yield select(getCharacter);
      if (health === 0) {
        yield call(characterLoseSaga);
        return false;
      }

      // Increase priority for character based on their speed
      yield put(addCharacterTurnPriority(characterAttackSpeed));
    }

    // Every turn, tick down skills recharging turns
    yield put(rechargeSkillsTurn());

    // Enough time between turns so mob attack isn't immediate
    yield delay(500);
  }
}

function* setupOpponentSaga(payload: string) {
  const slug = payload;
  const { level, baseStatsModifiers, baseStatsCosts, installedUpgrades } =
    getMobData(slug);

  // Calculate Mob base stats on level and modifiers
  const initialBaseStats = calculateMobBaseStats(
    level,
    baseStatsModifiers,
    baseStatsCosts
  );

  const upgradesStats = getUpgradesStats(
    installedUpgrades,
    initialBaseStats,
    initialBaseStats
  );

  // Set up health from max health, including boosts from mob upgrades
  const health = calculateMobStartingHealth(
    initialBaseStats.resilience,
    upgradesStats.maxHealth
  );

  // Save opponent data and stats into redux fight state
  yield put(
    setupOpponent({
      slug,
      health,
    })
  );
}

export function* characterOptionsSaga() {
  // Show options on mob to target

  // Wait for character to target a specific ship part
  const {
    weapons,
    shields,
    thrusters,
    targetingComputer,
    reactor,
    skill,
    item,
  } = yield race({
    weapons: take(targetWeapons),
    shields: take(targetShields),
    thrusters: take(targetThrusters),
    targetingComputer: take(targetTargetingComputer),
    reactor: take(targetReactor),
    skill: take(activateSkill),
    item: take(employFightItem),
  });
  if (weapons) {
    yield put(disableFightActions());
    yield call(characterRegularAttackSaga, BASE_STATS.FIREPOWER);
  }
  if (shields) {
    yield put(disableFightActions());
    yield call(characterRegularAttackSaga, BASE_STATS.RESILIENCE);
  }
  if (thrusters) {
    yield put(disableFightActions());
    yield call(characterRegularAttackSaga, BASE_STATS.SPEED);
  }
  if (targetingComputer) {
    yield put(disableFightActions());
    yield call(characterRegularAttackSaga, BASE_STATS.PRECISION);
  }
  if (reactor) {
    yield put(disableFightActions());

    yield call(characterRegularAttackSaga, BASE_STATS.ENERGY);
  }
  if (skill) {
    yield put(disableFightActions());
    yield call(activateSkillSaga, skill.payload);
  }
  if (item) {
    yield put(disableFightActions());
    yield call(employItemSaga, item);
  }
}

export function* characterRegularAttackSaga(
  baseStatWeakened: keyof CharacterBaseStats
) {
  const {
    data: { stats: characterStats },
  } = yield select(getCharacter);
  const { stats: opponentStats, totalBaseStats } = yield select(getOpponent);

  yield spawn(characterAttackAnimation);

  // Let laser hit first before showing opponent damage
  yield delay(100);

  let attackDamage = 0;
  let attackWeakenParts = 0;
  let isCriticalHit = false;

  // If attack precision is over 100%, the overage should reduce opponent's dodge chance
  const opponentDodgeChance = getDodgeChance(
    opponentStats.dodgeChance,
    characterStats.attackAccuracy
  );

  // Detemine if the attack hits based on dodge chance
  if (doesEventHappen(opponentDodgeChance)) {
    // Opponent dodges attack
    yield spawn(opponentDodgeAnimation);
  } else {
    // Roll for critical hit
    if (doesEventHappen(characterStats.criticalHitChance)) {
      // Get critical hit attack damage
      ({ attackDamage, attackWeakenParts } = getMultiplierAttackValues(
        characterStats.attackDamage,
        characterStats.weakenParts,
        characterStats.criticalHitMultiplier
      ));
      isCriticalHit = true;
    } else {
      // Get regular "random" attack damage
      ({ attackDamage, attackWeakenParts } = getRegularAttackValues(
        characterStats.attackDamage,
        characterStats.weakenParts,
        characterStats.attackAccuracy
      ));
    }
  }

  // Reduce attack damage by opponent's shields for total damage
  const { damage, weakenParts, isNullified } = getTotalDamageValuesAfterDefense(
    attackDamage,
    attackWeakenParts,
    opponentStats.damageReduction,
    opponentStats.weakenPartsReduction
  );

  // Apply damage to opponent health and weaken base stats
  yield put(
    attackOpponent({
      damage,
      weakenParts,
      baseStatWeakened,
      totalBaseStats,
      isCriticalHit,
    })
  );

  // If damage dealt, animate mob getting hit
  if (damage > 0 || weakenParts > 0) {
    yield spawn(opponentDamagedAnimation);
  }
  if (weakenParts > 0) {
    yield spawn(opponentWeakenedAnimation);
  }

  // If mob didn't dodge, but damage is still 0, highlight shields
  if (isNullified) {
    yield spawn(opponentNullifyAnimation);
  }
}

export function* characterSkillAttackSaga(
  skillDamage: number = 0,
  skillWeakenParts: number = 0,
  baseStatWeakened?: keyof CharacterBaseStats,
  ignoreDodge?: boolean,
  ignoreShields?: boolean
) {
  const {
    data: { stats: characterStats },
  } = yield select(getCharacter);
  const { stats: opponentStats, totalBaseStats } = yield select(getOpponent);

  const { attackDamage: attackDamageInfo, weakenParts: weakenPartsInfo } =
    DERIVED_STATS;
  let attackDamage = attackDamageInfo.rounder(skillDamage);
  let attackWeakenParts = weakenPartsInfo.rounder(skillWeakenParts);

  // If attack precision is over 100%, the overage should reduce opponent's dodge chance
  const opponentDodgeChance = getDodgeChance(
    opponentStats.dodgeChance,
    characterStats.attackAccuracy
  );

  // Detemine if the attack hits based on dodge chance
  if (!ignoreDodge && doesEventHappen(opponentDodgeChance)) {
    // Opponent dodges attack
    attackDamage = 0;
    attackWeakenParts = 0;
    yield spawn(opponentDodgeAnimation);
  }

  let damage = attackDamage;
  let weakenParts = attackWeakenParts;
  let isNullified = false;

  if (!ignoreShields) {
    // Reduce attack damage by opponent's shields for total damage
    ({ damage, weakenParts, isNullified } = getTotalDamageValuesAfterDefense(
      attackDamage,
      attackWeakenParts,
      opponentStats.damageReduction,
      opponentStats.weakenPartsReduction
    ));
  }

  // Apply damage to opponent health and weaken base stats
  yield put(
    attackOpponent({
      damage,
      weakenParts,
      baseStatWeakened,
      totalBaseStats,
      isCriticalHit: false,
    })
  );

  // If damage dealt, animate mob getting hit
  if (damage > 0 || weakenParts > 0) {
    yield spawn(opponentDamagedAnimation);
  }
  if (weakenParts > 0) {
    yield spawn(opponentWeakenedAnimation);
  }

  // If mob didn't dodge, but damage is still 0, highlight shields
  if (isNullified) {
    yield spawn(opponentNullifyAnimation);
  }
}

export function* characterAttackAnimation() {
  // Animate character attack
  yield put(animateAction("attack"));

  yield delay(300);

  // Reset animation
  yield put(endAnimation("attack"));
}

function* opponentDamagedAnimation() {
  // Animate opponent damaged
  yield put(animateOpponentAction("damaged"));

  yield delay(300);

  // Reset animation
  yield put(endOpponentAnimation("damaged"));
}

function* opponentWeakenedAnimation() {
  // Animate opponent damaged
  yield put(animateOpponentAction("weaken"));

  yield delay(500);

  // Reset animation
  yield put(endOpponentAnimation("weaken"));
}

function* opponentDodgeAnimation() {
  // Animate opponent dodge
  yield put(animateOpponentAction("dodge"));

  yield delay(300);

  // Reset animation
  yield put(endOpponentAnimation("dodge"));
}

function* opponentNullifyAnimation() {
  // Animate opponent's shields completely nullifying damage
  yield put(animateOpponentAction("nullify"));

  yield delay(500);

  // Reset animation
  yield put(endOpponentAnimation("nullify"));
}

function* opponentRegularAttackSaga() {
  const { stats: opponentStats, baseStatsTargets } = yield select(getOpponent);
  const {
    data: { totalBaseStats, currentBaseStats, stats: characterStats },
  } = yield select(getCharacter);

  yield spawn(opponentAttackAnimation);

  // Let laser hit first before showing character damage
  yield delay(100);

  // Get base stat target from mob
  const baseStatWeakened = getOpponentBaseStatTarget(
    baseStatsTargets,
    currentBaseStats
  );

  let attackDamage = 0;
  let attackWeakenParts = 0;
  let isCriticalHit = false;

  // If attack precision is over 100%, the overage should reduce character's dodge chance
  const characterDodgeChance = getDodgeChance(
    characterStats.dodgeChance,
    opponentStats.attackAccuracy
  );

  if (doesEventHappen(characterDodgeChance)) {
    // Character dodges attack
    yield spawn(characterDodgeAnimation);
  } else {
    // Roll for critical hit
    if (doesEventHappen(opponentStats.criticalHitChance)) {
      // Get critical hit attack damage
      ({ attackDamage, attackWeakenParts } = getMultiplierAttackValues(
        opponentStats.attackDamage,
        opponentStats.weakenParts,
        opponentStats.criticalHitMultiplier
      ));
      isCriticalHit = true;
    } else {
      // Get regular "random" attack damage
      ({ attackDamage, attackWeakenParts } = getRegularAttackValues(
        opponentStats.attackDamage,
        opponentStats.weakenParts,
        opponentStats.attackAccuracy
      ));
    }
  }

  // Reduce attack damage by character's shields for total damage
  const { damage, weakenParts, isNullified } = getTotalDamageValuesAfterDefense(
    attackDamage,
    attackWeakenParts,
    characterStats.damageReduction,
    characterStats.weakenPartsReduction
  );

  // Apply damage to character health
  yield put(
    takeDamage({
      damage,
      weakenParts,
      baseStatWeakened,
      totalBaseStats,
      isCriticalHit,
    })
  );

  // If damage dealt, animate character getting hit
  if (damage > 0 || weakenParts > 0) {
    yield spawn(characterDamagedAnimation);
  }
  if (weakenParts > 0) {
    yield spawn(characterWeakenedAnimation);
  }

  // If character didn't dodge, but damage is still 0, highlight shields
  if (isNullified) {
    yield spawn(characterNullifyAnimation);
  }
}

function* opponentAttackAnimation() {
  // Animate opponent attack
  yield put(animateOpponentAction("attack"));

  yield delay(300);

  // Reset opponent attack animation
  yield put(endOpponentAnimation("attack"));
}

function* characterDamagedAnimation() {
  yield delay(50);

  // Animate character damaged
  yield put(animateAction("damaged"));

  yield delay(300);

  // Reset character damaged animation
  yield put(endAnimation("damaged"));
}

function* characterWeakenedAnimation() {
  // Animate character damaged
  yield put(animateAction("weaken"));

  yield delay(500);

  // Reset animation
  yield put(endAnimation("weaken"));
}

function* characterDodgeAnimation() {
  // Animate character dodge
  yield put(animateAction("dodge"));

  yield delay(300);

  // Reset animation
  yield put(endAnimation("dodge"));
}

function* characterNullifyAnimation() {
  // Animate character shields completely nullifying damage
  yield put(animateAction("nullify"));

  yield delay(500);

  // Reset animation
  yield put(endAnimation("nullify"));
}

// End Fight Sagas

function* characterWinSaga() {
  // Get data from opponent
  const {
    data: {
      battleRatings,
      ui: { damage: characterDamages },
    },
  } = yield select(getCharacter);
  const { slug: mobSlug, damage: opponentDamages } = yield select(getOpponent);
  const { level, drops } = getMobData(mobSlug);

  // Animate opponent losing
  yield delay(200);
  yield call(opponentLoseAnimation);

  // Calculate Battle Performance - will affect exp, credits, drops
  const damageDealt = opponentDamages.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );
  const damageTaken = characterDamages.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );

  const battleRating = calculateBattleRating(damageDealt, damageTaken);
  const battleRatingData = getBattleRatingData(battleRating);

  // If mob battle rating is better than previous battle, update it
  const previousBattleRating = battleRatings[mobSlug];
  if (!previousBattleRating) {
    yield put(setMobBattleRating({ mob: mobSlug, rating: battleRating }));
  } else {
    const previousBattleRatingData = getBattleRatingData(previousBattleRating);
    if (battleRatingData.value > previousBattleRatingData.value) {
      yield put(setMobBattleRating({ mob: mobSlug, rating: battleRating }));
    }
  }

  // Gain experience and credits based on mob's level and battle rating
  const expGained = getExpGained(level, battleRatingData.rewardMultiplier);
  const creditsGained = getCreditsGained(
    level,
    battleRatingData.rewardMultiplier
  );
  const dropsGained = getMobDrops(drops, battleRating);

  yield call(gainExpSaga, expGained);
  yield call(gainCreditsSaga, creditsGained);
  if (dropsGained.length > 0) {
    // Character got new items or upgrades
    yield call(getObjectsSaga, { payload: dropsGained });
  }

  // Show fight results
  yield put(setFightStatus("winResults"));
  yield put(
    setFightResults({
      battleRating: battleRating,
      experience: expGained,
      credits: creditsGained,
      drops: dropsGained,
    })
  );
}

function* opponentLoseAnimation() {
  // Animate opponent losing
  yield put(animateOpponentAction("lose"));

  yield delay(3000);

  // Reset opponent lose animation
  yield put(endOpponentAnimation("lose"));
}

function* characterLoseSaga() {
  const {
    data: { currentLevelExp },
  } = yield select(getCharacter);

  // Animate character losing
  yield delay(100);
  yield call(characterLoseAnimation);

  // Take away exp from character
  const expLost = getExpLost(currentLevelExp);
  yield call(loseExpSaga, expLost);

  // Show fight results
  yield put(setFightStatus("loseResults"));
  yield put(
    setFightResults({
      experience: expLost,
    })
  );
}

function* characterLoseAnimation() {
  // Animate character losing
  yield put(animateAction("lose"));

  yield delay(3000);

  // Reset character lose animation
  yield put(endAnimation("lose"));
}

function* onwardFightWinSaga() {
  // Reset all fight data
  yield call(resetFightSaga);

  // Enable character movement
  yield put(enableMovement());

  // Show last win dialog from mob
  yield put(setFightStatus("winDialog"));
}

function* endFightWinSaga() {
  // This may not be called, clicking win dialog is optional
  yield put(setFightStatus("notFighting"));
}

function* onwardFightLoseSaga() {
  // Reset all fight data
  yield call(resetFightSaga);

  // Keep character movement locked

  // Show lose dialog from mob
  yield put(setFightStatus("loseDialog"));
}

function* endFightLoseSaga() {
  // After clicking lose dialog, send character back to Bishop City healed

  // Full heal
  yield call(healSaga);

  // Reset position back to Bishop City and directly to Bishop Shipworks
  const REPAIR_DIALOG =
    "We've fully repaired and restored your starship. It was in such rough, terrible shape that we felt bad charging you money to fix it. So... you're welcome.";
  const shipworksPosition = getBuildingData("bishopShipworks").position;
  yield call(
    enterAreaSaga,
    "bishopCity",
    shipworksPosition,
    "right",
    "bishopShipworks",
    "repair",
    REPAIR_DIALOG
  );

  yield put(setFightStatus("notFighting"));
}

function* resetFightSaga() {
  // Reset character damage
  yield put(resetDamage());

  // Restore all weakened parts
  yield put(restoreAllParts());

  // Reset character buffs
  yield put(resetBuffs());

  // Reset skills recharging
  yield put(resetSkillsRecharge());

  // Reset skills values just in case
  yield put(resetSkillsValues());

  // Reset opponent damage
  yield put(resetOpponentDamage());

  // Reset opponent weakened parts
  yield put(resetOpponentParts());

  // Reset opponent buffs
  yield put(resetOpponentBuffs());

  // Reset turn priorities
  yield put(resetTurnPriorities());

  // Reset fight results
  yield put(resetFightResults());
}

export default function* fightSagas() {
  // Only one fight at a time
  yield takeLeading(startMobDialog, startMobDialogSaga);
  yield takeLeading(previewFight, previewFightSaga);
  yield takeLeading(retreatFight, retreatFightSaga);
  yield takeLeading(startFight, startFightSaga);
  yield takeLeading(continueFight, continueFightSaga);
  yield takeLeading(fightAgain, fightAgainSaga);
  yield takeLeading(onwardFightWin, onwardFightWinSaga);
  yield takeLeading(endFightWin, endFightWinSaga);
  yield takeLeading(onwardFightLose, onwardFightLoseSaga);
  yield takeLeading(endFightLose, endFightLoseSaga);
}
